// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards{
    padding: 25px;
    background: #FFFFFF;
    border: none;
    border-radius: 14px;
}
.heading{
    color: #333333;
    font-size: 18px;
    font-weight: 500;
}
.para{
    color: #000000;
    font-size: 15px;
    text-align: justify;
}
.order-list-heading{
    color: #4169E1;
    font-size: 17px;
    font-weight: 500;
}
.about-us-banner{
    width: 100%;
}
@media screen and (min-width: 600px) {
    .about-us-banner > img{   
        height: 250px;
    }
}
@media screen and (min-width: 1000px) {
    .about-us-banner > img{   
        height: 290px;
    }
}
@media screen and (min-width: 1300px) {
    .about-us-banner > img{   
        height: 310px;
    }
}
@media screen and (min-width: 1500px) {
    .about-us-banner > img{   
        height: 330px;
    }
}
@media screen and (min-width: 1700px) {
    .about-us-banner > img{   
        height: 410px;
    }
}

.about-us-card{
    background: #FFFFFF;
    padding: 22px;
    border: none;
    border-radius: 12px;
    margin-top: 20px;
}

.about-us-card > div > .head {
    color: #333333;
    font-size: 23px;
    font-weight: 600;
    margin: 0px !important;
}

.about-us-card > div > .sub-heads {
    color: #666666;
    /* font-size: 15px; */
    /* font-weight: 500; */
}`, "",{"version":3,"sources":["webpack://./src/Pages/CompanyDetail/Terms/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,WAAW;AACf;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,sBAAsB;AAC1B","sourcesContent":[".cards{\n    padding: 25px;\n    background: #FFFFFF;\n    border: none;\n    border-radius: 14px;\n}\n.heading{\n    color: #333333;\n    font-size: 18px;\n    font-weight: 500;\n}\n.para{\n    color: #000000;\n    font-size: 15px;\n    text-align: justify;\n}\n.order-list-heading{\n    color: #4169E1;\n    font-size: 17px;\n    font-weight: 500;\n}\n.about-us-banner{\n    width: 100%;\n}\n@media screen and (min-width: 600px) {\n    .about-us-banner > img{   \n        height: 250px;\n    }\n}\n@media screen and (min-width: 1000px) {\n    .about-us-banner > img{   \n        height: 290px;\n    }\n}\n@media screen and (min-width: 1300px) {\n    .about-us-banner > img{   \n        height: 310px;\n    }\n}\n@media screen and (min-width: 1500px) {\n    .about-us-banner > img{   \n        height: 330px;\n    }\n}\n@media screen and (min-width: 1700px) {\n    .about-us-banner > img{   \n        height: 410px;\n    }\n}\n\n.about-us-card{\n    background: #FFFFFF;\n    padding: 22px;\n    border: none;\n    border-radius: 12px;\n    margin-top: 20px;\n}\n\n.about-us-card > div > .head {\n    color: #333333;\n    font-size: 23px;\n    font-weight: 600;\n    margin: 0px !important;\n}\n\n.about-us-card > div > .sub-heads {\n    color: #666666;\n    /* font-size: 15px; */\n    /* font-weight: 500; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
