import { i18n } from "@lingui/core"

export const locales = {
    en: "English",
    fil: "Filipino"
}

// const lang = localStorage.getItem("selectedLanguage");

//   if(lang === null){
//     localStorage.setItem("selectedLanguage", "en")
//   }

export const defaultLocale = localStorage.getItem("selectedLanguage") || "en";
localStorage.setItem("selectedLanguage", defaultLocale);

const catalogs = {
    en: async () => {
      const { messages } = await import(
        `./file.js!=!@lingui/loader!./locales/en/messages.po`
      )
      return messages;
    },

    fil: async ()=>{
      const {messages} = await import(
        `./file.js!=!@lingui/loader!./locales/fil/messages.po`
      )
      return messages;
    }
}

// export async function dynamicActivate(locale) {
//   const messages = await catalogs[locale]()
//   // i18n.loadAndActivate({ locale, messages })
//   i18n.load({ [locale]: messages });
//   i18n.activate(locale);
// }

export async function dynamicActivate(locale) {
  console.log(`Activating locale: ${locale}`);  // Debug log
  if (!catalogs[locale]) {
    console.error(`Locale ${locale} not found!`);
    return;
  }

  const messages = await catalogs[locale]();
  console.log("Loaded messages:", messages);   // Debugging

  i18n.load({ [locale]: messages });
  i18n.activate(locale);
  
  console.log("i18n locale set to:", i18n.locale);  // Debugging
}
