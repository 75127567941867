import { Oval, RotatingLines } from "react-loader-spinner";
import { Images } from "../../Components/Images/Images";
import { PATHS, SERVICES } from "../../config/Api_Url";
import PostRequest from "../../services/PostRequest";
import DeleteRequest from "../../services/DeleteRequest";
import GetRequest from "../../services/GetRequest";
import { useEffect, useState, useRef } from "react";

export const formatPathName = (input) => {
    if (typeof input !== 'string') {
        return '';
    }

    let cleanedText = input.replace(/\//g, '');
    let formattedText = cleanedText.charAt(0).toUpperCase() + cleanedText.slice(1);
    return formattedText;
};
export const generateHamBurgerPath = (pageName, category, IsEventDetail) => {
    const formattedPageName = formatPathName(pageName);
    const formattedCategory = formatPathName(category);
    let hamBurgerPath = `${formattedPageName}`;

    if (formattedCategory) {
        hamBurgerPath += ` / ${formattedCategory}`;
    }

    if (IsEventDetail) {
        hamBurgerPath += ` / Event Detail`;
    }

    return hamBurgerPath;
};


export const formatAndLowerPath = (pathname) => {
    if (pathname === '/home') {
        return 'home';
    }

    return formatPathName(pathname).toLowerCase().replace(/\s+/g, '-');
}
export const convertToTitleCase = (str) => {
    return str.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
}
export const capitalize = (s) => {
    if (s) {
        let txt = String(s).toLowerCase()
        return String(txt[0]).toUpperCase() + String(txt).slice(1);
    }
}
export const getTruncatedText = (string = '', from = 0, to = 200,) => {
    return string.slice(from, to);
}
export const ButtonComponent = ({ value, text, style, onClick, isActive, isDisabled, ...props }) => {
    return (<button {...props} disabled={isDisabled} value={value} onClick={onClick} className={`nav-btn ${isActive}`} style={style}>{text}</button>)
}

export const getUrlDetails = (url) => {
    try {
        const pathSegments = url.split('/').filter(Boolean);
        return {
            pageName: pathSegments[0],
            category: pathSegments[1],
            IsEventDetail: pathSegments[2] === 'event-detail' ? true : false
        }
    } catch (error) {
    }
}

export const CategoryButtonsComponent = ({ onClick = {}, buttons = [], isActive = '' }) => {
    const scrollContainerRef = useRef(null);
    const isMobileWidth = useMobileWidth(); // Check if it's mobile width

    const scroll = (direction) => {
        if (scrollContainerRef.current) {
          const { current } = scrollContainerRef;
          const scrollAmount = 200; // Adjust based on your needs
    
          if (direction === "left") {
            current.scrollLeft -= scrollAmount;
          } else {
            current.scrollLeft += scrollAmount;
          }
        }
      };

    let selector = (
        <select
            onChange={(e) => {
                console.log("e.target", e.target.value);
                onClick({ key: e.target.value.split(',')[0], value: e.target.value.split(',')[1] })
            }}
            className="form-select category-slt"
            aria-label="Default select example"
        >
            {
                buttons.map(([key, value], index) => {
                    return (
                        <option selected={value === 'All'} key={index} value={([key, value])}>{value}</option>
                    )
                })
            }

        </select>
    )

    let button = buttons.map(([key, value], index) => {
        let activeButton = isActive === value;


        return (
            <ButtonComponent
                isActive={activeButton ? 'active category-button' : 'category-button'}
                key={index}
                text={value}
                style={{
                    background: activeButton ? 'rgba(65, 105, 225, 0.1)' : 'rgba(169, 169, 169, 0.35)',
                    border: 'none',
                    color: activeButton ? 'rgba(65, 105, 225, 1)' : ' rgba(51, 51, 51, 1)',
                    margin: '0'
                }}
                onClick={() => onClick({ key, value })}
            />
        )
    })


    if (isMobileWidth) {
        return selector;
    } else {
        return (<div className="d-flex align-items-center gap-1">
            <span
                className="cp pointer"                
                onClick={() => scroll("left")}
            > {"<"} </span>
            <div ref={scrollContainerRef} className='home-category-btns'>{button} </div>
                <span
                className="cp pointer"                    
                onClick={() => scroll("right")}
            > {">"} </span>
        </div>)
    }
}
// export const CategoryButtonsComponent = ({ onClick = {}, buttons = [], isActive = '' }) => {

//     let button = buttons.map(([key, value], index) => {
//         let activeButton = isActive === value;
//         return (
//             <ButtonComponent
//                 isActive={activeButton ? 'active' : ''}
//                 key={index}
//                 text={value}
//                 style={{
//                     background: activeButton ? 'rgba(65, 105, 225, 0.1)' : 'rgba(169, 169, 169, 0.35)',
//                     border: 'none',
//                     color: activeButton ? 'rgba(65, 105, 225, 1)' : ' rgba(51, 51, 51, 1)',
//                     margin: '0 15px 0 0'
//                 }}
//                 onClick={() => onClick({ key, value })}
//             />
//         )
//     })
//     return (<div className='home-category-btns'>{button}</div>)
// }

export const LoaderComponent = ({ isLoading, ...props }) => {
    return (
        <RotatingLines
            visible={isLoading}
            height="20"
            width="20"
            color="grey"
            strokeColor="white"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperclassName=""
        />
    )
}
export const OvalLoaderComponent = ({ isLoading, ...props }) => {
    return (
        <Oval
            visible={isLoading}
            height="20"
            width="20"
            color="rgba(65, 105, 225, 1)"
            strokeColor="rgba(65, 105, 225, 1)"
            strokeWidth="5"
            secondaryColor="rgba(65, 105, 225, 1)"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
            {...props}
        />
    )
}
export const useSessionStorageData = () => {
    return (
        {
            googleAccessToken: sessionStorage.getItem('google-access-token') ? sessionStorage.getItem('google-access-token') : null,
            googleUserData: sessionStorage.getItem('google-user-data') ? JSON.parse(sessionStorage.getItem('google-user-data')) : null,
            apiUserData: sessionStorage.getItem('api-user-data') ? JSON.parse(sessionStorage.getItem('api-user-data')) : null,
        }
    )
}
// let sessionStorageData = useSessionStorageData();
// export const authID = sessionStorageData?.apiUserData?.userResBody?.auth0Id || sessionStorageData?.googleUserData?.user?.auth0UserId


export const ComingSoonComponent = ({ page = "" }) => {
    return (
        <div className="coming-soon-main">
            <span>{page} page will be coming soon.</span>
        </div>
    )
}


export const SortingDropdownComponent = ({ buttons = [], onClick, activeButton = 'All' }) => {
    return (
        <div className="sorting-dropdown">
            <button className='sport-sort-btn' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <div className='sport-sort-icon'>
                    <img src={Images.sortIcon} alt="sort-icon" />
                </div>
                <div className='sport-sort-txt'>Sort</div>
            </button>

            <ul className="dropdown-menu sort-menu p-4">
                <div className='sort-head'>
                    <span className='sort-by'>Sort By</span>
                    <span className='sort-cut-icon'>
                        <img src={Images.redCutIcon} alt="redcut-icon" />
                    </span>
                </div>
                <div className='sort-body'>
                    {
                        buttons.map(([key, value], index) => {
                            let _activeButton = activeButton === value
                            return (
                                <li onClick={() => onClick({ key, value })} className='sort-item' key={index}>
                                    <div className='sort-item-icon'>
                                        <img src={_activeButton ? Images.selectedIcon : Images.unSelectedIcon} alt="" />
                                    </div>
                                    <span className='sort-item-txt'>{value}</span>
                                </li>
                            )
                        })
                    }
                </div>
            </ul>
        </div>
    )

}
export const formatDate = (dateString, onlyDate = false) => {
    if (!dateString) {
        return ""
    }
    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    };
    const formattedTime = new Intl.DateTimeFormat('fil', options).format(date);
    const formattedTimeEng = new Intl.DateTimeFormat('en-Us', options).format(date);

    return localStorage.getItem("selectedLanguage") === "fil" ? formattedTime : formattedTimeEng;

}
export const formatDate1 = (dateString, onlyDate = false) => {
    if (!dateString) {
        return ""
    }

    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    };
    const formattedTime = new Intl.DateTimeFormat('fil', options).format(date);
    const formattedTimeEng = new Intl.DateTimeFormat('en-Us', options).format(date);

    return localStorage.getItem("selectedLanguage") === "fil" ? formattedTime : formattedTimeEng;
}

export const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const addBearerToken = (headers, token) => {

    let removeCoutes = String(token).replace(/"/g, '');

    if (!token.startsWith('Bearer ')) {
        token = `Bearer ${removeCoutes}`;
    }

    headers.Authorization = token;

    return headers;
};

export const useMobileWidth = () => {
    const [isMobileWidth, setIsMobileWidth] = useState(window.innerWidth < 768);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setIsMobileWidth(window.innerWidth < 768);
        });

        resizeObserver.observe(document.documentElement);

        return () => resizeObserver.disconnect();
    }, []);

    return isMobileWidth;
};

export const save_favorite = async (event_id) => {

    try {
        let save_favorite = await PostRequest(SERVICES.eventService, `${PATHS.favouriteEvents.save_favorite}/${event_id}`)
        // toast.success('Event save successfully.')
        return save_favorite

    } catch (error) {
        // toast.error('An error occured.')
    }
}

export const remove_favorite = async (event_id) => {
    try {
        let remove_favorite = await DeleteRequest(SERVICES.eventService, `${PATHS.favouriteEvents.remove_favorite}/${event_id}`)
        // toast.success('Event remove successfully.')
        return remove_favorite
    } catch (error) {
        // toast.error(error.response.data.error || 'An error occured.')
    }
}

export const toggleFavorite = async (isSaved, event_ID) => {
    const response = !isSaved ? await save_favorite(event_ID) : await remove_favorite(event_ID)
    return response
};

export const get_subcategory = async (category) => {
    try {
        let subcategory = await GetRequest(SERVICES.accountService, `public/${category}/${PATHS.event_subcategory}`)
        return {
            subcategory: subcategory.data.data
        }
    } catch (error) {
    }
}

export const round_off_deciamls = (capital) => {
    const decimalPart = capital % 1;
    const roundedOdds = decimalPart >= 0.56 ? Math.ceil(capital) : Math.floor(capital);

    return roundedOdds;
}

export const to_locale_string = (str = "") => {
    let number = Number(str) || 0;
    return number.toLocaleString();
}

export const NotFoundComponent = ({ heading, paragraph }) => {
    return (
        <div className='no-event-main'>
            <div className="no-event-img"></div>
            <div className='no-event-under-main'>
                <div className='head'>{heading}</div>
                <div className='para'>{paragraph} </div>
            </div>
        </div>
    )
}