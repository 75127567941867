import React from 'react'
import './Footer.css'
import { Images } from '../Images/Images'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/react/macro'

const linksUrls = [
    {
        name: "Contact us",
        size: 2,
        link: [
            {
                image: Images.footer.TelegramLogo,
                name: "Telegram",
                target: "_blank",
                link: "https://t.me/BizAK88"
            },
            {
                image: Images.referal.Facebook,
                name: "Facebook",
                target: "_blank",
                link: "https://www.facebook.com/akalako88/"
            },
            {
                image: Images.referal.Insta,
                name: "Instagram",
                target: "_blank",
                link: "https://www.instagram.com/akalako88/"
            }
        ]
    },
    {
        name: "Company",
        size: 3,
        link: [
            {
                name: "About Us",
                link: "/about-us"
            },
            {
                name: "Terms & Conditions",
                link: "/terms-and-condition"
            },
            {
                name: "Privacy Policy",
                link: "privacy-policy"
            }
        ]
    }
]
const image = {
    name: "Payment Methods",
    link: [
        Images.footer.GCash,
        Images.footer.Maya,
        Images.footer.Qrph,
        Images.footer.Cebuana,
        Images.footer.InstaPay,
        Images.footer.Bdo,
        Images.footer.SevenEleven,
    ]
}
export const Footer = () => {
    return (
        <>
            <div className='row m-0 mt-4 footer-main'>
                <div className='col-md-4 col-sm-12'>
                    <img src={Images.akalaKoLogo} width="150px"/>
                    <p className='image-para'><Trans>AkalaKo88 is a fun and exciting prediction platform where you can bet on a wide variety of events—ranging from sports, TV shows, politics, weather, and more! Use your knowledge and intuition to predict outcomes and win real big! Its easy to play and easy to win!</Trans></p>
                </div>
                {
                    linksUrls?.map((data, index)=>{
                        return <div key={index} className={`px-3 py-2 col-6 col-md-${data?.size}`}>
                            <p className='header'>{data?.name}</p>
                            {
                                data?.link?.map((data, index)=>{
                                    return <Link to={data?.link} key={index} target={data?.target} className='d-flex mb-3 gap-1 align-items-center text-decoration-none'>
                                        {data.image && <img src={data.image} alt="" width="32px" />}
                                        <p className='links mb-0'>{data.name}</p>
                                    </Link>
                                })
                            }
                        </div>
                    })
                }
                <div className='col-md-3 col-12 px-3 py-2'>
                    <p className='header'>{image?.name}</p>
                    <div className='d-flex gap-3'>
                        <div className='d-flex flex-column gap-2'>
                            <Link to="my-wallet"><img className='footer-image' src={Images.footer.GCash} alt=""/></Link>
                            <Link to="my-wallet"><img className='footer-image' src={Images.footer.Maya} alt=""/></Link>
                            <Link to="my-wallet"><img className='footer-image' src={Images.footer.Qrph} alt=""/></Link>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <Link to="my-wallet"><img className='footer-image' src={Images.footer.Cebuana} alt=""/></Link>
                            <Link to="my-wallet"><img className='footer-image' src={Images.footer.InstaPay} alt=""/></Link>
                            <Link to="my-wallet"><img className='footer-image' src={Images.footer.Bdo} alt=""/></Link>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <img className='footer-image' src={Images.footer.SevenEleven} alt=""/>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <span className='copyright-line'>&#169; All Rights Reserved @ 2024</span>
                </div>
            </div>
        </>
    )
}
