import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBIN6Ov7cFL7IncHCT6V12EDxPsNQEamTQ",
    authDomain: "akalabet-d88cd.firebaseapp.com",
    projectId: "akalabet-d88cd",
    storageBucket: "akalabet-d88cd.appspot.com",
    messagingSenderId: "766391057914",
    appId: "1:766391057914:web:692e57826d7254fe43b843",
    measurementId: "G-42HJZ35F5S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY
// ✅ Check if Firebase Messaging is supported in the current browser
export const firebaseMessaging = async () => {
    const supported = await isSupported(); // Check for API support
    if (!supported) {
        console.warn("Push notifications are NOT supported in this browser.");
        return null;
    }

    const messaging = getMessaging(app, {
        experimentalForceLongPolling: true, // ✅ Fix Safari issues
        useFetchStreams: false, // ✅ Fix for fetch-related errors
    });

    return messaging;
};
