import React, { useContext, useEffect, useState } from 'react'
import './Sidebar.css'
import { Images } from '../Images/Images';
import { Link, useLocation } from 'react-router-dom';
import { ButtonComponent,  getUrlDetails, useMobileWidth } from '../../Utils/Helper/HelperFunction';
import { BetContext } from '../Context/UseContext';
import GetRequest from '../../services/GetRequest';
import { PATHS, SERVICES } from '../../config/Api_Url';
import { t } from '@lingui/core/macro';

export const Sidebar = ({ isCollapsed, setIsCollapsed }) => {
    const isMobileWidth = useMobileWidth();
    const { isMobile, setIsMobile } = useContext(BetContext);
    const { isAuthenticatedWith, setShowForm, isLogin } = useContext(BetContext);
    const location = useLocation();
    const { pageName } = getUrlDetails(location.pathname);
    const [activeTab, setActiveTab] = useState(pageName);
    const [, setCategory] = useState([])

    const get_category = async () => {
        try {
            let category = await GetRequest(SERVICES.accountService, PATHS.event_category);
            setCategory(category.data.data)
        } catch (error) {
        }
    }

    useEffect(() => {
        get_category()
    }, [])

    const SIDEBAR_ITEMS = [
        { id: "home", title: t`Home`, icon: { black: Images.sidebar.home.black, red: Images.sidebar.home.red } },
        ...(isLogin
            ? [
                {
                    id: "active-bets",
                    title: t`Active Bets`,
                    icon: { black: Images.sidebar.activeBets.black, red: Images.sidebar.activeBets.red },
                },
                // {
                //     id: "parley-bets",
                //     title: "Parley Bets",
                //     icon: { black: Images.sidebar.activeBets.black, red: Images.sidebar.activeBets.red },
                // },
            ]
            : []),
        { id: "sports", title: t`Sports`, icon: { black: Images.sidebar.sports.black, red: Images.sidebar.sports.red } },
        { id: "politics", title: t`Politics`, icon: { black: Images.sidebar.politics.black, red: Images.sidebar.politics.red } },
        { id: "entertainment", title: t`Entertainment`, icon: { black: Images.sidebar.entertainment.black, red: Images.sidebar.entertainment.red } },
        { id: "weather", title: t`Weather`, icon: { black: Images.sidebar.weather.black, red: Images.sidebar.weather.red } },
        { id: "esports", title: t`Esports`, icon: { black: Images.sidebar.esports.black, red: Images.sidebar.esports.red } },
        { id: "social-media", title:t `Social Media`, icon: { black: Images.sidebar.socialMedia.black, red: Images.sidebar.socialMedia.red } },
        { id: "celebrities", title: t`Celebrities`, icon: { black: Images.sidebar.celebrities.black, red: Images.sidebar.celebrities.red } },
        { id: "tv-shows", title:t `TV Shows`, icon: { black: Images.sidebar.tvShows.black, red: Images.sidebar.tvShows.red } },
        { id: "stocks", title: t`Stocks`, icon: { black: Images.sidebar.stocks.black, red: Images.sidebar.stocks.red } },
        { id: "cryptocurrencies", title: t`Cryptocurrencies`, icon: { black: Images.sidebar.cryptocurrencies.black, red: Images.sidebar.cryptocurrencies.red } },
        { id: "lotto", title: t`Lotto`, icon: { black: Images.sidebar.lotto.black, red: Images.sidebar.lotto.red } },
    ];

    function IconComponent({ onClick, icon }) {
        return <img onClick={onClick} src={icon} alt='icon' height="25px" width="25px"/>
    }

    function SidebarItem({ item, activeTab, setActiveTab, isSidebarCollapsed, setIsCollapsed }) {

        return (
            <Link
                className={`sidebar-item ${activeTab === item.id ? 'sidebar-item__active' : 'sidebar-item__inactive'}`}
                onClick={() => {
                    setActiveTab(item.id)
                    if(window.innerWidth < 700){
                        setIsCollapsed(!isSidebarCollapsed)
                    }
                }}
                to={`${item.id}/all`}
            >

                {/* Active background when the tab is active */}
                {activeTab === item.id && (<div className="sidebar-item__active-bg" />)}

                {/* Icon */}
                <span className="sidebar-item__icon">
                    <IconComponent icon={activeTab === item.id ? item.icon.red : item.icon.black} />
                </span>

                {/* Title with conditional rendering based on collapsed state */}
                <span className="sidebar-item__title"
                    style={{
                        visibility: isSidebarCollapsed ? 'hidden' : 'visible', // Hide when collapsed
                        opacity: isSidebarCollapsed ? 0 : 1, // Smooth transition for opacity
                        transition: 'opacity 0.3s ease, visibility 0.3s ease', // Smooth transitions
                    }}
                >
                    {item.title}
                </span>
            </Link>
        );
    }



    return (
        <div className={`sidebar ${isMobile}`}
            style={{
                // width: isCollapsed ? `${(window.screen.width < 768) ? '0' : '90px'}` : '225px',
                // padding: isCollapsed ? `${(window.screen.width < 768) ? '0' : ''}` : '',
                // overflow: isCollapsed ? `${(window.screen.width < 768) ? 'hidden' : ''}` : '',
                width: isCollapsed ? `${(isMobileWidth) ? '0' : '90px'}` : '225px',
                padding: isCollapsed ? `${(isMobileWidth) ? '0' : ''}` : '',
                overflow: isCollapsed ? `${(isMobileWidth) ? 'hidden' : ''}` : '',
            }} >
            {/* <div className='sidebar-logo'>
                <img className='logo' src={Images.logoWithText} alt="logo" />
                </div> */}
            <div className={`sidebar-logo-main ${isCollapsed ? 'm-y-1' : 'm-y-3'}`} style={isCollapsed ?
                {
                    justifyContent: 'center',
                    marginTop: '1.5rem'
                }
                : {}
            } >
                <Link to={`home/all`}>
                    <div style={{ margin: isCollapsed ? '1rem 0' : '' }} >
                        <img className='logo' src={Images.akalaKoLogo} alt="logo" />
                    </div>
                </Link>
                <button className="sidebar__collapse-button px-0" onClick={() => setIsCollapsed(!isCollapsed)} >
                    {isCollapsed ? <IconComponent icon={Images.sidebar.hamburgerClose} /> : <IconComponent onClick={() => setIsMobile('mobile-sidebar')} icon={Images.sidebar.hamburgerOpen} />}
                </button>
            </div>
            <div className='side-menus'>
                {SIDEBAR_ITEMS.map((item) => (
                    <SidebarItem
                        isSidebarCollapsed={isCollapsed}
                        setIsCollapsed= {setIsCollapsed}
                        key={item.id}
                        item={item}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                ))}
            </div>
            {
                !(isAuthenticatedWith.social || isAuthenticatedWith.normal) &&
                <div className='mobile-btns-main'>
                    <div className='mobile-btns'>
                        <ButtonComponent
                            onClick={() => setShowForm({ login: true })}
                            style={{
                                color: 'rgba(210, 43, 43, 1)',
                                background: 'none',
                                width: '100%'
                            }}
                            text={'Login'} />
                        <ButtonComponent
                            onClick={() => setShowForm({ signup: true })}
                            style={{
                                background: 'rgba(210, 43, 43, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                width: '100%'
                            }}
                            text={'Sign Up'} />
                    </div>
                </div>
            }
        </div >
    );

}


