const environment = {
    staging: {
        accountService: process.env.REACT_APP_ACCOUNT_STAGE,
        eventService: process.env.REACT_APP_EVENT_STAGE,
        bettingService: process.env.REACT_APP_BETTING_STAGE,
        paymentService: process.env.REACT_APP_PAYMENT_STAGE,
        notificationService: process.env.REACT_APP_NOTIFICATION_STAGE,
        bannerService: process.env.REACT_APP_BANNER_STAGE,
        wss: process.env.REACT_APP_WSS_STAGE,
        payborit: {
            sandbox: 'https://sandbox.payborit.com/api',
            production: 'https://app.payborit.com/api'
        }
    },
    production: {
        accountService: process.env.REACT_APP_ACCOUNT_PROD,
        eventService: process.env.REACT_APP_EVENT_PROD,
        bettingService: process.env.REACT_APP_BETTING_PROD,
        paymentService: process.env.REACT_APP_PAYMENT_PROD,
        notificationService: process.env.REACT_APP_NOTIFICATION_PROD,
        bannerService: process.env.REACT_APP_BANNER_PROD,
        wss: process.env.REACT_APP_WSS_PROD,
        payborit: {
            sandbox: 'https://sandbox.payborit.com/api',
            production: 'https://app.payborit.com/api'
        }
    }
}

export const SERVICES = environment[process.env.REACT_APP_ENVIROMENT_VARIABLE];

export const PATHS = {
    register: 'register',
    verify_mobile_otp: 'verify_mobile_otp',
    resend_mobile_otp: "resend_mobile_otp",
    change_password: "change_password",
    login: 'login',
    logout: 'logout',
    forgot_password: 'forgot-password',
    socialLogin: 'social-login',
    active_events: 'active_events',
    profile: 'profile',
    event_category: 'public/event-category',
    event_subcategory: 'subcategory',
    eventDetail: 'event_by_id',
    place_bet: 'place_bets',
    get_bets: 'get_bets',
    get_bet_ById: 'get_bet_ById',
    get_parley_bets: 'get_parley_bets',
    get_parley_bet_byId: 'get_parley_bet_byId',
    calculate_payouts: 'calculate_payouts',
    bet_cash_out: 'bet_cash_out',
    add_balance: 'add_balance',
    get_balance: 'get_balance',
    withdrawl_balance: 'withdrawl_balance',
    txn_history: 'txn_history',
    wallet_history: 'wallet_history',
    comment: {
        add_comment: 'comment',
        comment_like: 'comment/like', // pass comment id
        commnet_reply: 'comment/reply', // pass comment id
    },
    favouriteEvents: {
        get_favorite_event: 'get_favorite_event',
        remove_all_favorite: 'remove_all_favorite',
        remove_favorite: 'remove_favorite', // pass event_id
        save_favorite: 'save_favorite', // pass event_id
    },
    redeem_coupon: 'coupon/redeem',
    betting_history: 'user/betting-history',
    current_bet: 'user/current_bet',
    bet_stats: 'user/bet_stats',
    calculate_parley_payout: 'calculate_parley_payout',
    place_payley_bets: "place_payley_bets",
    public_banner: "public/banner",
    payment_gateway_api: {
        check_payment_status: 'check_payment_status',
        checkout: 'checkout',
        disbursement: 'disbursement',
        payment_channels: 'payment_channels',
        supported_channels: 'supported_channels',
    },
    get_all_referred_users: "referral/getAllReferredUsers",
    get_my_referred_user: "affiliate/getMyReferredUser",
    get_bonus_and_referral_stat: 'referral/getBonusAndReferralStat',
    affiliate_request: "referral/affiliate-request",
    notification: {
        fcm_token_save: 'fcm-token-save',
        get_all_notification: 'Get_All_notification', // pass status
        read_notification: 'read_notification', // pass notification id
        read_all_notfication: 'update_notification_status',
    },
    combo_panalo: {
        create_combo_panalo: 'create_combo_panalo',
        get_single_combo: 'getSingleCombo', // comboId
        get_combo_panalos: 'get_combo_panalos',
        update_combo_panalo: 'update_combo_panalo', // comboId 
        delete_combo_panalo: 'delete_combo_panalo' // delete combo panalo 
    }
}